/*
 * Package Import
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { toast } from 'react-toastify';

/*
 * Local Import
 */
import APICall from '../../commons/APICall';
import { SLACK_CHANNEL_LIST, SLACK_DELETE_MESSAGES } from '../../data/constants';
import ChannelPicker from '../ChannelPicker';
import DatePicker from '../DatePicker';
import Validate from '../Validate';
import * as S from './style';

/*
 * Component
 */
function App() {
    const [status, setStatus] = useState('idle');
    const [date, setDate] = useState();
    const [selectedChannel, setSelectedChannel] = useState(null);

    const {
        mutateAsync: deleteChannelMessages,
        isLoading: isDeleteLoading,
    } = useMutation({
        mutationFn: (payload) => APICall.makeRequest('POST', SLACK_DELETE_MESSAGES, payload),
        onSuccess: () => {
            toast.success('Les messages on été supprimés avec succès');
        },
        onError: (error) => {
            if (error?.response?.status === 403) {
                return toast.warn('Tu n\'a pas les droits suffisants pour effectuer cette action !');
            }
            return toast.error('Une erreur est survenue lors de la suppression des messages');
        },
    });

    const {
        data: channelsList,
        isLoading,
        isError,
        isFetching,
    } = useQuery(['channels'], () => APICall.makeRequest('GET', SLACK_CHANNEL_LIST).then(({ channels }) => channels));

    return (
        <S.Container>
            <S.Title>Scarlett</S.Title>
            <ChannelPicker
                isError={isError}
                channels={channelsList}
                loading={isLoading || isFetching}
                onChannelSelected={setSelectedChannel}
            />
            <DatePicker date={date} setDate={setDate} />
            <Validate
                status={status}
                setStatus={setStatus}
                onValidate={deleteChannelMessages}
                channel={selectedChannel}
                date={date}
                loading={isDeleteLoading}
            />
        </S.Container>
    );
}

/*
 * Export
 */
export default App;
