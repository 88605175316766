import FormData from 'form-data';
import axios from 'axios';

const { API_BASEURL } = process.env;

export default class APICall {
    static baseURL = `${API_BASEURL || (`${document.location.protocol}//${document.location.host}`)}/api`;

    static token = null;

    static setToken(token) {
        this.token = token;
    }

    static makeRequest(method, endpoint, data = {}, headersParam = {}, timeout = 10000) {
        let headers = {
            'cache-control': 'no-cache, private',
            Authorization: `Bearer ${this.token || null}`,
            ...headersParam,
        };
        if (data instanceof FormData) {
            headers = {
                'Content-Length': data.getLengthSync(), // Sinon ça marche pas !
                ...headers,
                ...data.getHeaders(),
            };
        }
        const urlConfig = {
            headers,
            method,
            url: APICall.baseURL + endpoint,
            withCredentials: true,
            timeout,
        };

        if (method.toUpperCase() === 'GET') {
            urlConfig.params = data;
        }
        else {
            urlConfig.data = data;
        }
        return axios(urlConfig)
            .catch((error) => {
                if (!error) {
                    throw new Error('La session n\'est plus valide, vous devez vous reconnecter');
                }
                else throw error;
            })
            .then((response) => response?.data)
            .catch((error) => {
                // Dispatch un event qui sera capturé dans le component, pour la gestion qu'on ne peux pas faire
                // Comme un fonctionnement général qui devrait rediriger l'utilisateur
                window.dispatchEvent(new CustomEvent('api_error', { detail: error }));

                if (!error.response || !error.response.status) {
                    throw error;
                }
                if (error.response.status === 401) {
                    // En cas de 401, il faut déconnecter l'utilisateur
                    // store.dispatch(logoutAction());
                }

                if (error.response.data && error.response.data.message) {
                    throw new Error(error.response.data.message);
                }
                throw error;
            });
    }
}
