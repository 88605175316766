/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Icon, Modal } from 'semantic-ui-react';
import dayjs from 'dayjs';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
function Validate({ status, setStatus, onValidate, channel, date, loading }) {
    const [open, setOpen] = useState(false);

    const handleValidate = () => {
        setStatus('loading');
        setOpen(false);
        onValidate({ channelId: channel.id, date });
    };

    // Reset fields when new status is received
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={(
                <S.SemanticButton
                    color="green"
                    loading={loading}
                    disabled={loading}
                >
                    Valider
                </S.SemanticButton>
            )}
        >
            {
                (!date || !channel)
                    ? (
                        <>
                            {/* A field is still empty 🛑 */}
                            <Modal.Header>Tous les champs ne sont pas remplis</Modal.Header>
                            <Modal.Content image>
                                <S.SemanticDescription>
                                    <p>Veuillez remplir tous les champs avant de valider votre action</p>
                                </S.SemanticDescription>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="black" onClick={() => setOpen(false)}>
                                    Retour
                                </Button>
                            </Modal.Actions>
                        </>
                    )
                    : (
                        <>
                            {/* All fields are OK ✅ */}
                            <Modal.Header>Supprimer les messages</Modal.Header>
                            <Modal.Content image>
                                <S.SemanticDescription>
                                    <S.Paragraph>
                                        Vous vous apprêtez à supprimer les messages du cannal Slack <S.Span>{channel.title}</S.Span> jusqu'au <S.Span>{dayjs(date).format('DD-MM-YYYY')} (exclu)</S.Span>.
                                    </S.Paragraph>
                                    <S.Paragraph>Êtes-vous d’accord ?</S.Paragraph>
                                </S.SemanticDescription>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="black" onClick={() => setOpen(false)}>
                                    Non
                                </Button>
                                <Button
                                    content="Valider"
                                    labelPosition="right"
                                    icon="checkmark"
                                    onClick={handleValidate}
                                    positive
                                />
                            </Modal.Actions>
                        </>
                    )
            }
        </Modal>
    );
}

Validate.propTypes = {
    status: PropTypes.string.isRequired,
    setStatus: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    channel: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
    }),
    date: PropTypes.string,
    loading: PropTypes.bool,
};

Validate.defaultProps = {
    date: undefined,
    channel: null,
    loading: false,
};
/*
 * Export
 */
export default Validate;
