/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
export const Buttons = styled.div({
  margin: '1rem 0 0 0',
});
