/*
 * Package Import
 */
import { createRoot } from 'react-dom/client';
import React from 'react';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import packageJSON from '../package.json';

/*
 * Local Import
 */
import KeycloakProvider from './commons/KeycloakProvider';
import queryClient from './queryClient';
import App from './components/App';

import 'react-toastify/dist/ReactToastify.css';

require('semantic-ui-css/semantic.min.css');

const { SENTRY_DSN } = process.env;

Sentry.init({
    dsn: SENTRY_DSN,

    release: packageJSON.version,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

});

const RootReactElement = (
    <QueryClientProvider client={queryClient}>
        <KeycloakProvider>
            <App />
            <ToastContainer />
        </KeycloakProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(RootReactElement);
