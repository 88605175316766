/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'semantic-ui-react';
import dayjs from 'dayjs';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
function DatePicker({ date, setDate }) {
    const handleDateChange = (e, data) => {
        setDate(data.value);
    };

    const handleDaysChange = (e, data) => {
        setDate(dayjs().subtract(data.value, 'day').format('YYYY-MM-DD'));
    };

    const dayValue = () => {
        // set today at midnight to handle entire day
        const today = dayjs().startOf('day');
        const desiredDate = dayjs(date);
        return today.diff(desiredDate, 'day');
    };

    const handleButtonClick = (data) => {
        setDate(dayjs().subtract(data, 'day').format('YYYY-MM-DD'));
    };

    return (
        <>
            <h2>Choisissez une date (exclue) ou le nombre de jours</h2>
            <Input
                placeholder="Choisissez une date"
                onChange={handleDateChange}
                value={date || ''}
                type="date"
            />
            <Input
                value={dayValue() || 0}
                onChange={handleDaysChange}
                type="number"
                min="-1"
            />
            <S.Buttons>
                <Button onClick={() => handleButtonClick(30)}>30 jours</Button>
                <Button onClick={() => handleButtonClick(10)}>10 jours</Button>
                <Button onClick={() => handleButtonClick(0)}>Hier inclus</Button>
                <Button onClick={() => handleButtonClick(-1)}>Aujourd’hui inclus</Button>
            </S.Buttons>
        </>
    );
}

DatePicker.propTypes = {
    date: PropTypes.string,
    setDate: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
    date: '',
};

/*
 * Export
 */
export default DatePicker;
