/*
 * Package Import
 */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Search, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
function ChannelPicker({ channels, onChannelSelected, isError, loading }) {
    const [channelsList, setChannelsList] = useState(channels);

    useEffect(() => {
        setChannelsList(channels);
    }, [channels]);

    const handleSearchChange = useCallback((e, { value }) => {
        setChannelsList(channels.filter((channel) => channel.name.toLowerCase().includes(value.toLowerCase())));
    }, [channels, channelsList]);

    const formattedChannels = useMemo(
        () => channelsList
            ?.map((channel) => ({ id: channel.id, title: `#${channel.name}`, description: channel?.purpose?.value })) || [],
        [channelsList],
    );

    const handleChannelSelected = useCallback(
        (event, { result: selectedChannel }) => onChannelSelected?.(selectedChannel),
        [onChannelSelected],
    );

    return (
        <>
            <h2>Choisis un canal :</h2>
            <S.Container>
                <Search
                    className="channel-picker"
                    loading={loading}
                    size="large"
                    placeholder="Choisis le channel Slack à nettoyer"
                    onResultSelect={handleChannelSelected}
                    onSearchChange={handleSearchChange}
                    results={formattedChannels}
                    // value={value}
                    noResultsMessage="Aucun résultat"
                />
            </S.Container>
            {
                isError && (
                    <Message negative>
                        <Message.Header>Oups ! Un truc s'est mal passé !</Message.Header>
                        <p>Une erreur est survenue lors de la récupération des cannaux, veuillez rééssayer plus tard.</p>
                    </Message>
                )
            }
        </>
    );
}

ChannelPicker.propTypes = {
    channels: PropTypes.array,
    onChannelSelected: PropTypes.func,
    loading: PropTypes.bool,
    isError: PropTypes.bool,
};
ChannelPicker.defaultProps = {
    channels: [],
    onChannelSelected: () => {},
    loading: false,
    isError: false,
};

/*
 * Export
 */
export default ChannelPicker;
