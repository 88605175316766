/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
export const Container = styled.div({
    width: '60em',
    margin: '10em auto',
});

export const Title = styled.h1({
    fontSize: '4em',
});
