/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Button, Modal } from 'semantic-ui-react';

/*
 * Local Import
 */
export const Paragraph = styled.p({
    wordBreak: 'break-all',
});

export const SemanticButton = styled(Button)({
    display: 'block !important',
    margin: '3em 0 0 0 !important',
});

export const SemanticDescription = styled(Modal.Description)({
    width: '100%',
});

export const Span = styled.span({
    fontWeight: 'bold',
});
