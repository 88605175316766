/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
export const Container = styled.div({
    display: 'flex',

    '& > .channel-picker': {
        input: {
            width: '30em',
            borderRadius: '.28571429rem !important',
        },
    },
    '& > .card-rejected': {
        width: '30em',
        margin: '0 0 0 1em',
    },
});

export const ErrorContainer = styled.div({
    display: 'flex',
});
