// Pas besoin d'importer, c'est récupéré depuis le HTML sur le serveur de O'clock
// import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// eslint-disable-next-line no-undef
const keycloak = Keycloak({
    url: process.env.KEYCLOAK_URL,
    realm: 'oclock',
    clientId: 'scarlett',
    scope: 'email groups profile roles',
    onLoad: 'login-required',
});

export default keycloak;
