/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';

import { CircularProgress, Box, Typography } from '@mui/material';

import keycloakInstance from './Keycloak';
import APICall from './APICall';

export const isAuthorized = (keycloak, ORMode = true) => (roles = []) => {
    let _roles = roles;
    if (_roles && !Array.isArray(_roles)) {
        _roles = [_roles];
    }
    if (_roles && _roles.length === 0) {
        return true;
    }

    if (_roles && _roles.length > 0) {
        if (ORMode) {
            return _roles.some((r) => {
                let _role = r;
                let _ressource = null;
                const explodedRole = r.split(':');
                if (explodedRole.length === 2) {
                    _role = explodedRole[1];
                    _ressource = explodedRole[0];
                }
                return keycloak.hasRealmRole(_role) || keycloak.hasResourceRole(_role, _ressource);
            });
        }

        return _roles.reduce((accumulator, r) => {
            let _role = r;
            let _ressource = null;
            const explodedRole = r.split(':');
            if (explodedRole.length === 2) {
                _role = explodedRole[1];
                _ressource = explodedRole[0];
            }
            return accumulator && (keycloak.hasRealmRole(_role) || keycloak.hasResourceRole(_role, _ressource));
        }, true);
    }
    return true;
};

export const useAuthorization = (roles = [], ORMode = true) => {
    const { keycloak } = useKeycloak();
    return isAuthorized(keycloak, ORMode)(roles);
};

export const withKeycloak = (WrappedComponent) => (
    function (props) {
        const { keycloak } = useKeycloak();
        return (
            <WrappedComponent keycloak={keycloak} {...props} />
        );
    }
);

export const withAuthorization = (WrappedComponent) => (
    function (props) {
        const { keycloak } = useKeycloak();
        return (
            <WrappedComponent isAuthorized={isAuthorized(keycloak)} {...props} />
        );
    }
);

function KeycloakProvider({ children }) {
    const [isReady, setIsReady] = useState(false);

    const eventLogger = (event, error) => {
        switch (event) {
        case 'onReady': {
            if (!keycloakInstance.authenticated) {
                keycloakInstance.login();
            }
            return null;
        }
        default:
            return null;
        }
    };

    const onTokens = ({ token }) => {
        if (token) {
            APICall.setToken(token);
            localStorage.setItem('profile', JSON.stringify(keycloakInstance.tokenParsed));
            setIsReady(true);
        }
    };

    return (
        <ReactKeycloakProvider
            authClient={keycloakInstance}
            onEvent={eventLogger}
            onTokens={onTokens}
        >
            {isReady && keycloakInstance.authenticated ? (
                children
            ) : (
                <Box style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - ( 4em + 16px ))',
                    marginTop: '-4em',
                    flexDirection: 'column',
                }}
                >
                    <Typography variant="h1" component="h1">
                        Scarlett
                    </Typography>
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        Communication avec keycloak en cours ...
                        <CircularProgress
                            size="1em"
                            style={{
                                marginLeft: '1em',
                            }}
                        />
                    </Typography>
                </Box>
            )}
        </ReactKeycloakProvider>
    );
}

export default KeycloakProvider;
